// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
  font-family: "Ubuntu";
  src: url("/fonts/Ubuntu-Regular.woff2") format("woff2"),
    url("/fonts/Ubuntu-Regular.woff") format("woff"),
    url("/fonts/Ubuntu-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu";
  src: url("/fonts/Ubuntu-Bold.woff2") format("woff2"),
    url("/fonts/Ubuntu-Bold.woff") format("woff"),
    url("/fonts/Ubuntu-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "glamoushSans";
  src: url("/fonts/BivouacGlamoushSans.ttf") format("ttf"),
    url("/fonts/BivouacGlamoushSans.otf") format("otf"),
    url("/fonts/BivouacGlamoushSans.woff") format("woff"),
    url("/fonts/BivouacGlamoushSans.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

p {
  font-size: 1.125rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2.5rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.3rem;
}
label {
  font-size: 1.5rem;
}
input {
  font-family: "Ubuntu", sans-serif;
  font-size: 1.25rem;
}
button {
  font-family: "Ubuntu", sans-serif;
  font-size: 1.5rem;
}
.text-emphasis {
  font-size: 2rem;
}
.text-code {
  font-family: Consolas, "Courier New", monospace;
}
